import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./SuratJalan copy.css";
import usePrintRaceStore from "../zustand/Store/untukPrint/PrintRace";
import DetailSpRace from "../routes/Race/WebAdmin/splist/Detailsplist/DetailSpRace";
import { Col, Row } from "react-bootstrap";
import JsBarcode from "jsbarcode-fixed";
import moment from "moment";
const SuratJalanPrintAll = () => {
  const setDetailSJRace = usePrintRaceStore((state) => state.setDetailSJRace);
  const DetailSJRace = usePrintRaceStore((state) => state.DetailSJRace);

  useEffect(() => {
    const dataString = localStorage.getItem("printData");
    if (dataString) {
      const data = JSON.parse(dataString);
      setDetailSJRace(data);
    }
  }, [setDetailSJRace]);

  useEffect(() => {
    console.log("DetailSJRace updated:", DetailSJRace);
    if (DetailSJRace && DetailSJRace.length > 0) {
      for (let index = 0; index < DetailSJRace.length; index++) {
        JsBarcode(`#barcode${index}`, `${DetailSJRace[index].sm}`, {
          format: "CODE128",
          lineColor: "#00000",
          width: 1.9,
          height: 50,
          displayValue: true,
        });
      }
      window.print();
    }
  }, [DetailSJRace]);
  const date = new Date();
  const hariini = moment(date).format(`YYYY-MM-DD`);
  useEffect(() => {
    document.title = `SJRCPrint_All_${hariini}_1hal`;
  }, []);
  return (
    <div style={{ backgroundColor: "white" }}>
      {DetailSJRace && DetailSJRace.length > 0 ? (
        DetailSJRace.map((item, index) => (
          <div key={index} className="container ">
            <div className="mt-2">
              <div className="d-flex">
                <Row style={{ width: "100%", fontWeight: "bold" }}>
                  <Col>
                    <img
                      className="justify-content-start"
                      src="https://track.rajacepat.com/assets/Race_New_Baru-6e871e8a.png"
                      alt=""
                    />
                  </Col>
                  <Col>
                    <div className="text-center justify-content-center">
                      <br />
                      <h2 style={{ fontSize: 16 }}>
                        <b>SURAT JALAN</b>
                      </h2>
                    </div>
                  </Col>
                  <Col>
                    <div className="text-end justify-content-end">
                      <svg id={`barcode${index}`}></svg>
                    </div>
                  </Col>
                </Row>
              </div>
              <br />
              <Row  style={{fontWeight:'bold'}} className="row">
                <Col span={7} style={{ fontSize: 10 }}>
                  <table>
                    <tbody>
                      <tr>
                        <td className="nowrap">Nomor Surat Jalan</td>
                        <td className="mx-2">:</td>
                        <td>{item.sm}</td>
                      </tr>
                      <tr>
                        <td className="nowrap">Tanggal</td>
                        <td className="mx-2">:</td>
                        <td>{item.pickupDate}</td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <Col span={5} style={{ fontSize: 10 }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>Nama Driver</td>
                        <td className="mx-2">:</td>
                        <td>{item.driver}</td>
                      </tr>
                      <tr>
                        <td>Plat Nomor</td>
                        <td className="mx-2">:</td>
                        <td>{item.nopol}</td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <Row style={{fontWeight:'bold'}}>
                  <Col style={{ fontSize: 10 }}>
                    <table>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              backgroundColor: "",
                              marginRight: 10,
                              width: 95,
                            }}
                            className="nowrap ontop"
                          >
                            Alamat
                          </td>
                          <td
                            style={{ backgroundColor: "" }}
                            className="ontop mx-2 mr-5"
                          >
                            :
                          </td>
                          <td>{item.alamatBongkar}</td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </Row>
            </div>
            <br />

            <table
              style={{ fontSize: 10, border: "2px solid  black" }}
              className="table table-bordered"
            >
              <thead
                className="text-center"
                style={{ border: "2px solid black" }}
              >
                <tr style={{ border: "2px solid black" }}>
                  <th
                    style={{ border: "2px solid black" }}
                    rowSpan="2"
                    className="align-middle"
                  >
                    NO
                  </th>
                  <th
                    style={{ border: "2px solid black" }}
                    rowSpan="2"
                    className="align-middle"
                  >
                   NO SJ PELANGGAN
                  </th>
                  <th
                    style={{ border: "2px solid black" }}
                    rowSpan="2"
                    className="align-middle"
                  >
                    NAMA PELANGGAN
                  </th>
                  <th style={{ border: "2px solid black" }} colSpan="3">
                    JUMLAH
                  </th>
                </tr>
                <tr style={{ border: "2px solid black" }}>
                  <th style={{ border: "2px solid black" }}>FAKTUR</th>
                  <th style={{ border: "2px solid black" }}>COLLY</th>
                  <th style={{ border: "2px solid black" }}>IKAT</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ textAlign: "center", border: "2px solid black" }}>
                  <td style={{ border: "2px solid black" }}>{1}</td>
                  <td style={{ border: "2px solid black" }}>{item.sjErl}</td>
                  <td style={{ border: "2px solid black" }}>
                    {item.sekolahTujuan}
                  </td>
                  <td style={{ border: "2px solid black" }}>
                    {item.jumlahFaktur}
                  </td>
                  <td style={{ border: "2px solid black" }}>{item.koli}</td>
                  <td style={{ border: "2px solid black" }}>{item.ikat}</td>
                </tr>
                <tr>
                  <td
                    colSpan="3"
                    style={{
                      fontWeight: "bold",
                      fontSize: "8px",
                    }}
                  >
                    TOTAL
                  </td>
                  <td
                    style={{ textAlign: "center", border: "2px solid black" }}
                  >
                    {item.jumlahFaktur}
                  </td>
                  <td
                    style={{ textAlign: "center", border: "2px solid black" }}
                  >
                    {item.koli}
                  </td>
                  <td
                    style={{ textAlign: "center", border: "2px solid black" }}
                  >
                    {item.ikat}
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ fontSize: 10 }}>
              <b>Note :</b>
            </div>
            <div
              style={{
                border: "2px solid black",
                padding: 10,
                textAlign: "start",
              }}
            >
              <p>
                <b>
                  <i style={{ fontSize: 12, fontWeight: "bold" }}>
                  Buku yang diterima 100% menjadi tanggung jawab penerima dan
                    tidak bisa diserahkan ke pihak manapun tanpa sepengetahuan
                    pengirim. Jika ada yang pihak-pihak yang mengatasnamakan
                    RAJA CEPAT dapat menghubungi Customer Service PT Raja Cepat
                    Nusantara di nomor 089506185458.
                  </i>
                </b>
              </p>
            </div>
            {/* <br /> */}
            <div className="row text-center mt-4">
              <Col style={{ fontSize: 10, fontWeight: "bold" }}>
                <p>Pengirim</p>
                <br />
                <br />
                <br />
                <p>(.............................................)</p>
                <p>
                  <i>Nama, Tanda Tangan, dan Tanggal</i>
                </p>
                <p>
                  <i>No HP:............................................</i>
                </p>
              </Col>
              <Col style={{ fontSize: 10, fontWeight: "bold" }}>
                <p>Penerima</p>
                <br />
                <br />
                <br />
                <p>(.............................................)</p>
                <p>
                  <i>Nama, Tanda Tangan, dan Tanggal</i>
                </p>
                <p>
                  <i>No HP:............................................</i>
                </p>
              </Col>
            </div>
            {index % 2 == 0 && <hr className="new2" />}
          </div>
        ))
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

export default SuratJalanPrintAll;
