export const notifications = [
  {
    id: 1,
    image: "https://avatars.githubusercontent.com/u/130539563?s=200&v=4",
    name: "Domnic",
    time: "6:19 PM",
    message: "There are many variations of passages of...",
    badge: 2
  },
  {
    id: 2,
    image: "https://avatars.githubusercontent.com/u/130539563?s=200&v=4",
    name: "John Smith",
    time: "4:18 PM",
    message: "Lorem Ipsum is simply dummy text of the...",

  },
  {
    id: 3,
    image:"https://avatars.githubusercontent.com/u/130539563?s=200&v=4",
    name: "John Smith",
    time: "7:10 PM",
    message: "The point of using Lorem Ipsum is that it has a...",
    // badge: 8
  },
  {
    id: 4,
    image: "https://avatars.githubusercontent.com/u/130539563?s=200&v=4",
    name: "alex dolgove",
    time: "5:10 PM",
    message: "It is a long established fact that a reader will...",
  },
  {
    id: 5,
    image: "https://avatars.githubusercontent.com/u/130539563?s=200&v=4",
    name: "Domnic Harris",
    time: "7:35 PM",
    message: "All the Lorem Ipsum generators on the...",
    // badge: 3
  }
];
