import React from "react";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";

function PrintCustomer() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id_customer = queryParams.get("id_customer");

  // Mengambil state yang dikirim
  const customerData = location.state ? location.state.customerData : null;
  const salesData = location.state ? location.state.salesData : null;

  console.log("ID Customer:", id_customer);
  console.log("sales:", salesData);
  return (
    <div id="outtable" style={{ height: "auto", width: "auto" }}>
      <div style={{ padding: "50px" }}>
        <table
          width="100%"
          cellpadding="1"
          style={{ border: "1px solid black" }}
        >
          <tr>
            <td width="110px" style={{ border: "1px solid black" }}>
              <div style={{ padding: "10px", marginLeft: "10%" }}>
                <img
                  src="https://elogs.eurekalogistics.co.id/assets/admin/dist/img/logo-eurekalogistics.png"
                  class="img-circle"
                  width="110px"
                />
              </div>
            </td>
            <td
              align="center"
              colSpan="3"
              style={{ border: "1px solid black" }}
            >
              <b>E-FORM MASTER DATA & ANALISA SURVEY</b> <br />
              <i>Master Data Application & Survey Analysis Form</i>
            </td>
            <td width="140px" align="" style={{ border: "1px solid black" }}>
              <div style={{ padding: "5px", marginLeft: "10%" }}>
                CUSTOMER CODE <br />
                <p>#{customerData.kode_customer}</p>
                {/* <strong>{DataKodeMitraZustand.kode_mitra}</strong> */}
              </div>
            </td>
            <td width="110px" height="100px" align="">
              <div style={{ padding: "10px" }}>
                <img
                  src="https://elogs.eurekalogistics.co.id/qr/EM00318.png"
                  class="img-circle"
                  width="100%"
                />
              </div>
            </td>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <td style={{ backgroundColor: "black" }} colSpan={12}>
              {" "}
            </td>
          </tr>

          {/* Nama Dan ALAMAT PERUSAHAAN */}
          <>
            <td
              align="center"
              colSpan="8"
              style={{
                border: "1px solid black",
                height: "40px",
                paddingTop: "10px",
              }}
            >
              <b>
                {" "}
                NAMA DAN ALAMAT CUSTOMER (<i>Sold to Party</i>){" "}
              </b>
            </td>
            <tr>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                REGISTRASI AWAL
              </td>{" "}
              <td
                width="250px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                {customerData.tgl_bergabung}
                {/* {DataKodeMitraZustand.awal_kontrak} */}
              </td>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                TANGGAL BERDIRI
              </td>{" "}
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                {customerData.tgl_bediri}
                {/* {DataKodeMitraZustand.akhir_kontrak} */}
              </td>
            </tr>
            <tr>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                ITEM
              </td>{" "}
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                {customerData.jenis_barang}
                {/* {DataKodeMitraZustand.title} */}
              </td>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                NAMA CUSTOMER
              </td>{" "}
              <td
                align="left"
                colSpan={3}
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                {customerData.nama_perusahaan}
                {/* {DataKodeMitraZustand.nama_mitra} */}
              </td>
            </tr>

            <tr>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                KTP CUSTOMER
              </td>{" "}
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                {customerData.ktp}
                {/* {DataKodeMitraZustand.title} */}
              </td>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                EMAIL CUSTOMER
              </td>{" "}
              <td
                align="left"
                colSpan={3}
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                {customerData.email}
                {/* {DataKodeMitraZustand.nama_mitra} */}
              </td>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <td style={{ backgroundColor: "black" }} colSpan={12}>
                {" "}
              </td>
            </tr>
          </>

          {/* ALAMAT */}
          <>
            <tr>
              <td
                width="100px"
                rowspan="3"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                ALAMAT
              </td>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                JALAN
              </td>
              <td
                align="left"
                colspan="4"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                {customerData.alamat_kantor}
                {/* {DataKodeMitraZustand.alamat} */}
              </td>
            </tr>
            <tr>
              <td
                width="100px"
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                KOTA
              </td>
              <td
                width="130px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
              </td>
              <td
                width="100px"
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                PROVINSI
              </td>
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
                colspan="4"
              >
                {" "}
              </td>
            </tr>
            <tr>
              <td
                width="100px"
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                KODEPOS
              </td>
              <td
                width="130px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                -
              </td>
              <td
                width="100px"
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                HOMEPAGE
              </td>
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
                colspan="4"
              >
                {" "}
                {/* {DataKodeMitraZustand.homepage} */}
              </td>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <td style={{ backgroundColor: "black" }} colSpan={12}>
                {" "}
              </td>
            </tr>
            <tr>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                TELP. KANTOR
              </td>{" "}
              <td
                width="250px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                {customerData.telepon}
                {/* {DataKodeMitraZustand.telepon} */}
              </td>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                HP/WA
              </td>{" "}
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
                colSpan={3}
              >
                {" "}
                {customerData.hp}
                {/* {DataKodeMitraZustand.telp} */}
              </td>
            </tr>
            <tr>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                FAX. KANTOR
              </td>{" "}
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                {customerData.fax}
                {/* {DataKodeMitraZustand.fax} */}
              </td>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                TAHUN BERDIRI
              </td>{" "}
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
                colSpan={3}
              >
                {" "}
                {/* {DataKodeMitraZustand.tahun_berdiri} */}
              </td>
            </tr>
          </>

          {/* CONTACT PERSON */}
          <>
            <tr style={{ textAlign: "center" }}>
              <td style={{ backgroundColor: "black" }} colSpan={12}>
                {" "}
              </td>
            </tr>
            <tr align="center">
              <td
                colspan="6"
                style={{ border: "1px solid black", height: "40px" }}
              >
                {" "}
                <b> CONTACT PERSON </b>
              </td>
            </tr>{" "}
            <tr>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                PIC KANTOR
              </td>{" "}
              <td
                width="250px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                {customerData.pic_office}
              </td>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                EMAIL PIC
              </td>{" "}
              <td
                width="250px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                {customerData.pic_email}
              </td>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                JABATAN PIC
              </td>{" "}
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
                colSpan={4}
              >
                {" "}
                {customerData.pic_position}
              </td>
            </tr>
            <tr>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                TELP PIC
              </td>{" "}
              <td
                width="250px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                {customerData.pic_phone}
              </td>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                PIC BIRTHDAY
              </td>{" "}
              <td
                width="250px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                {customerData.pic_birth}
              </td>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                PIC FAX
              </td>{" "}
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
                colSpan={4}
              >
                {" "}
                {customerData.pic_fax}
              </td>
            </tr>
          </>

          {/* DATA PENANGGUNG JAWAB */}
          <>
            <tr style={{ textAlign: "center" }}>
              <td style={{ backgroundColor: "black" }} colSpan={12}>
                {" "}
              </td>
            </tr>
            <tr align="center">
              <td
                colspan="6"
                style={{ border: "1px solid black", height: "40px" }}
              >
                {" "}
                <b>
                  {" "}
                  DATA PENANGGUNG JAWAB (<i>Person In Charge</i>){" "}
                </b>
              </td>
            </tr>{" "}
            {/* akunting */}
            <>
              <tr>
                <td
                  colspan="6"
                  style={{ border: "1px solid black", padding: "10px" }}
                >
                  {" "}
                  <b> AKUNTING</b>
                </td>
              </tr>
              <tr>
                <td
                  width="100px"
                  style={{ border: "1px solid black", padding: "10px" }}
                >
                  AKUNTING{" "}
                </td>{" "}
                <td
                  width="250px"
                  style={{ border: "1px solid black", padding: "10px" }}
                >
                  {customerData.akunting === "Y" ? "ADA" : "TIDAK ADA"}
                </td>
                <td
                  width="100px"
                  style={{ border: "1px solid black", padding: "10px" }}
                >
                  AKUNTING DATE
                </td>{" "}
                <td
                  align="left"
                  style={{ border: "1px solid black", padding: "10px" }}
                  colSpan={4}
                >
                  {" "}
                  {customerData.akunting_date}
                </td>
              </tr>
              <tr>
                <td
                  width="100px"
                  style={{ border: "1px solid black", padding: "10px" }}
                >
                  AKUNTING MEMO{" "}
                </td>{" "}
                <td
                  colspan={5}
                  width="250px"
                  style={{ border: "1px solid black", padding: "10px" }}
                >
                  {" "}
                  {customerData.akunting_memo}
                </td>
              </tr>
            </>
            {/* MANAGER */}
            <>
              <tr>
                <td
                  colspan="6"
                  style={{ border: "1px solid black", padding: "10px" }}
                >
                  {" "}
                  <b> MANAGER</b>
                </td>
              </tr>
              <tr>
                <td
                  width="100px"
                  style={{ border: "1px solid black", padding: "10px" }}
                >
                  MANAGER{" "}
                </td>{" "}
                <td
                  width="250px"
                  style={{ border: "1px solid black", padding: "10px" }}
                >
                  {" "}
                  <td width="250px">
                    {customerData.manager === "Y" ? "ADA" : "TIDAK ADA"}
                  </td>
                </td>
                <td
                  width="100px"
                  style={{ border: "1px solid black", padding: "10px" }}
                >
                  MANAGER DATE
                </td>{" "}
                <td
                  align="left"
                  style={{ border: "1px solid black", padding: "10px" }}
                  colSpan={4}
                >
                  {" "}
                  {customerData.manager_date}
                </td>
              </tr>
              <tr>
                <td
                  width="100px"
                  style={{ border: "1px solid black", padding: "10px" }}
                >
                  MANAGER MEMO{" "}
                </td>{" "}
                <td
                  colspan={5}
                  width="250px"
                  style={{ border: "1px solid black", padding: "10px" }}
                >
                  {" "}
                  {customerData.manager_memo}
                </td>
              </tr>
            </>
            {/* DIREKTUR */}
            <>
              <tr>
                <td
                  colspan="6"
                  style={{ border: "1px solid black", padding: "10px" }}
                >
                  {" "}
                  <b> DIREKTUR</b>
                </td>
              </tr>
              <tr>
                <td
                  width="100px"
                  style={{ border: "1px solid black", padding: "10px" }}
                >
                  DIREKTUR{" "}
                </td>{" "}
                <td
                  width="250px"
                  style={{ border: "1px solid black", padding: "10px" }}
                >
                  {" "}
                  <td width="250px">
                    {customerData.direktur === "Y" ? "ADA" : "TIDAK ADA"}
                  </td>
                </td>
                <td
                  width="100px"
                  style={{ border: "1px solid black", padding: "10px" }}
                >
                  DIREKTUR DATE
                </td>{" "}
                <td
                  align="left"
                  style={{ border: "1px solid black", padding: "10px" }}
                  colSpan={4}
                >
                  {" "}
                  {customerData.direktur_date}
                </td>
              </tr>
              <tr>
                <td
                  width="100px"
                  style={{ border: "1px solid black", padding: "10px" }}
                >
                  DIREKTUR MEMO{" "}
                </td>{" "}
                <td
                  colspan={5}
                  width="250px"
                  style={{ border: "1px solid black", padding: "10px" }}
                >
                  {" "}
                  {customerData.direktur_memo}
                </td>
              </tr>
            </>
            <tr style={{ textAlign: "center" }}>
              <td style={{ backgroundColor: "black" }} colSpan={12}>
                {" "}
              </td>
            </tr>
          </>

          {/* DATA ACCOUNTING */}
          <>
            <tr style={{ textAlign: "center" }}>
              <td style={{ backgroundColor: "black" }} colSpan={12}>
                {" "}
              </td>
            </tr>
            <tr align="center">
              <td
                colspan="6"
                style={{ border: "1px solid black", height: "40px" }}
              >
                {" "}
                <b> TAX </b>
              </td>
            </tr>{" "}
            <tr>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                NPWP NUMBER
              </td>{" "}
              <td
                width="250px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                {customerData.npwp}
                {/* {DataKodeMitraZustand.nama_bank} */}
              </td>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                NPWP ADDRESS
              </td>{" "}
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
                colSpan={4}
              >
                {" "}
                {customerData.alamat_npwp}
                {/* {DataKodeMitraZustand.nama_akun} */}
              </td>
            </tr>
            <tr>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                KTP NUMBER
              </td>{" "}
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                {customerData.ktp}
                {/* {DataKodeMitraZustand.no_rek} */}
              </td>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                TDP NUMBER
              </td>{" "}
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
                colSpan={4}
              >
                {" "}
                {customerData.tdp}
                {/* {DataKodeMitraZustand.currency} */}
              </td>
            </tr>
            <tr>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                TAX PIC
              </td>{" "}
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                {customerData.tax_pic}
                {/* {DataKodeMitraZustand.no_rek} */}
              </td>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                TAX POSITION
              </td>{" "}
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
                colSpan={4}
              >
                {" "}
                {customerData.tax_position}
                {/* {DataKodeMitraZustand.currency} */}
              </td>
            </tr>
            <tr>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                TAX EMAIL
              </td>{" "}
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                {customerData.tax_email}
                {/* {DataKodeMitraZustand.no_rek} */}
              </td>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                TAX MOBILE
              </td>{" "}
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                {customerData.tax_mobile}
                {/* {DataKodeMitraZustand.no_rek} */}
              </td>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                TAX PHONE OFFICE
              </td>{" "}
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
                colSpan={4}
              >
                {" "}
                {customerData.tax_phone_office}
                {/* {DataKodeMitraZustand.currency} */}
              </td>
            </tr>
          </>
          {/* DATA ACCOUNTING */}
          <>
            <tr align="center">
              <td
                colspan="6"
                style={{ border: "1px solid black", height: "40px" }}
              >
                {" "}
                <b> TERM OF PAYMENT </b>
              </td>
            </tr>{" "}
            <tr>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                BANK NAME
              </td>{" "}
              <td
                width="250px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                {customerData.nama_bank}
                {/* {DataKodeMitraZustand.nama_bank} */}
              </td>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                ACCOUNT NAME
              </td>{" "}
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
                colSpan={4}
              >
                {" "}
                {customerData.nama_akun}
                {/* {DataKodeMitraZustand.nama_akun} */}
              </td>
            </tr>
            <tr>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                ACCOUNT NUMBER
              </td>{" "}
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                {customerData.no_rek}
                {/* {DataKodeMitraZustand.no_rek} */}
              </td>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                CURRENCY
              </td>{" "}
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
                colSpan={4}
              >
                {" "}
                {customerData.mata_uang}
                {/* {DataKodeMitraZustand.currency} */}
              </td>
            </tr>
            <tr>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                TYPE OF PAYMENT
              </td>{" "}
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                {customerData.jenis_pembayaran}
                {/* {DataKodeMitraZustand.no_rek} */}
              </td>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                TERM OF PAYMENT
              </td>{" "}
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
                colSpan={4}
              >
                {" "}
                {customerData.top}
                {/* {DataKodeMitraZustand.currency} */}
              </td>
            </tr>
            <tr>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                BANK PIC
              </td>{" "}
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                {customerData.bank_pic}
                {/* {DataKodeMitraZustand.no_rek} */}
              </td>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                BANK POSITION
              </td>{" "}
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
                colSpan={4}
              >
                {" "}
                {customerData.bank_position}
                {/* {DataKodeMitraZustand.currency} */}
              </td>
            </tr>
            <tr>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                BANK EMAIL
              </td>{" "}
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                {customerData.bank_email}
                {/* {DataKodeMitraZustand.no_rek} */}
              </td>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                BANK MOBILE
              </td>{" "}
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                {customerData.bank_mobile}
                {/* {DataKodeMitraZustand.no_rek} */}
              </td>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                BANK OFFICE PHONE
              </td>{" "}
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
                colSpan={4}
              >
                {" "}
                {customerData.bank_phone_office}
                {/* {DataKodeMitraZustand.currency} */}
              </td>
            </tr>
          </>
          {/* DATA INVOICE */}
          <>
            <tr style={{ textAlign: "center" }}>
              <td style={{ backgroundColor: "black" }} colSpan={12}>
                {" "}
              </td>
            </tr>
            <tr align="center">
              <td
                colspan="6"
                style={{ border: "1px solid black", height: "40px" }}
              >
                {" "}
                <b>
                  {" "}
                  DATA INVOICE (<i>Invoice Information</i>){" "}
                </b>
              </td>
            </tr>{" "}
            <tr>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                INV PIC
              </td>{" "}
              <td
                width="250px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                {customerData.invoice_pic}
                {/* {DataKodeMitraZustand.nama_bank} */}
              </td>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                INV POSITION
              </td>{" "}
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
                colSpan={4}
              >
                {" "}
                {customerData.invoice_position}
                {/* {DataKodeMitraZustand.nama_akun} */}
              </td>
            </tr>
            <tr>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                INV EMAIL
              </td>{" "}
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                {customerData.invoice_email}
                {/* {DataKodeMitraZustand.no_rek} */}
              </td>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                INV OFFICE PHONE
              </td>{" "}
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
                colSpan={4}
              >
                {" "}
                {customerData.invoice_phone_office}
                {/* {DataKodeMitraZustand.currency} */}
              </td>
            </tr>
            <tr>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                INV MOBILE
              </td>{" "}
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                {" "}
                {customerData.invoice_mobile}
                {/* {DataKodeMitraZustand.no_rek} */}
              </td>
              <td
                width="100px"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                INV ADDRESS
              </td>{" "}
              <td
                align="left"
                style={{ border: "1px solid black", padding: "10px" }}
                colSpan={4}
              >
                {" "}
                {customerData.invoice_address}
                {/* {DataKodeMitraZustand.currency} */}
              </td>
            </tr>
          </>

          {/* Tanda Tangan */}
          <tr style={{ textAlign: "center" }}>
            <td style={{ backgroundColor: "black" }} colSpan={12}>
              {" "}
            </td>
          </tr>
        </table>
        <table width="100%" style={{ borderCollapse: "collapse" }}>
          <tr>
            <td
              colSpan="6"
              style={{ border: "1px solid black", padding: "10px" }}
            >
              <b>DIBUAT OLEH (Created By)</b>
            </td>
            <td
              colSpan="4"
              style={{ border: "1px solid black", padding: "10px" }}
            >
              <b>DIPERIKSA OLEH (Checked By)</b>
            </td>
          </tr>
          <tr style={{ height: "100px" }}>
            <td
              colSpan="3"
              style={{ border: "1px solid black", padding: "10px" }}
            ></td>
            <td
              colSpan="2"
              style={{ border: "1px solid black", padding: "10px" }}
            ></td>
            <td
              colSpan="4"
              style={{ border: "1px solid black", padding: "10px" }}
            ></td>
            <td
              colSpan="6"
              style={{ border: "1px solid black", padding: "10px" }}
            ></td>
          </tr>
          <tr>
            <td
              colSpan="3"
              style={{ border: "1px solid black", padding: "10px" }}
            >
              <b>{salesData}</b> <br /> MARKETING
            </td>
            <td
              colSpan="3"
              style={{ border: "1px solid black", padding: "10px" }}
            >
              <b>Ragil Yoliono</b> <br /> ATASAN MARKETING
            </td>
            <td
              colSpan="3"
              style={{ border: "1px solid black", padding: "10px" }}
            >
              <b>Ernanto Tri Atmojo</b> <br /> AKUNTING
            </td>
            <td
              colSpan="3"
              style={{ border: "1px solid black", padding: "10px" }}
            >
              <b>{customerData.nama_perusahaan}</b>
              <br />
            </td>
          </tr>
          <tr>
            <td
              colSpan="12"
              style={{ border: "1px solid black", padding: "10px" }}
              align="right"
            ></td>
          </tr>
        </table>
      </div>
    </div>
  );
}

export default PrintCustomer;
