import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./SuratJalan.css";
import PrintRace from "../zustand/Store/untukPrint/PrintRace";
import DetailSpRace from "../routes/Race/WebAdmin/splist/Detailsplist/DetailSpRace";
import { Col, Row } from "react-bootstrap";
import JsBarcode from "jsbarcode-fixed";
import moment from "moment";
const SuratJalan = () => {
  const setDetailSJRace = PrintRace((state) => state.setDetailSJRace);
  const DetailSJRace = PrintRace((state) => state.DetailSJRace);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    console.log(`iniurl`, urlParams);
    const dataString = urlParams.get("data");
    if (dataString) {
      const data = JSON.parse(decodeURIComponent(dataString));
      setDetailSJRace(data);
      JsBarcode(`#barcode`, `${data.sm}`, {
        format: "CODE128",
        lineColor: "#00000",
        width: 1.9,
        height: 50,
        displayValue: true,
      });
    }
  }, [setDetailSJRace]);

  useEffect(() => {
    console.log("DetailSJRace updated:", DetailSJRace);
    window.print();
  }, [DetailSJRace]);
  const date = new Date();
  const hariini = moment(date).format(`YYYY-MM-DD`);
  useEffect(() => {
    document.title = `SJRCPrint_All_${hariini}_1hal`;
  }, []);
  return (
    <div style={{ backgroundColor: "white" }}>
      <div className="container mb-5">
        <div>
          <div className="d-flex">
            <Row style={{ width: "100%" }}>
              <Col>
                <img
                  className="justify-content-start"
                  src="https://track.rajacepat.com/assets/Race_New_Baru-6e871e8a.png"
                  alt=""
                />
              </Col>
              <Col>
                <div className="text-center justify-content-center">
                  <br />
                  <h2 style={{ fontSize: 16 }}>
                    <b>SURAT JALAN</b>
                  </h2>
                </div>
              </Col>
              <Col>
                <div className="text-end justify-content-end">
                  <svg id={`barcode`}></svg>
                </div>
              </Col>
            </Row>
          </div>
          <br />
          <br />
          <Row className="row">
            <Col span={7} style={{ fontSize: 12 }}>
              <table>
                <tbody>
                  <tr>
                    <td className="nowrap">Nomor Surat Jalan</td>
                    <td className="mx-2">:</td>
                    <td>{DetailSJRace.sm}</td>
                  </tr>
                  <tr>
                    <td className="nowrap">Tanggal</td>
                    <td className="mx-2">:</td>
                    <td>{DetailSJRace.pickupDate}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col span={5} style={{ fontSize: 12 }}>
              <table>
                <tbody>
                  <tr>
                    <td>Nama Driver</td>
                    <td className="mx-2">:</td>
                    <td>{DetailSJRace.driver}</td>
                  </tr>
                  <tr>
                    <td>Plat Nomor</td>
                    <td className="mx-2">:</td>
                    <td>{DetailSJRace.nopol}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
            <Col span={5} style={{ fontSize: 12 }}>
                <table>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          backgroundColor: "",
                          // marginRight: 10,
                          width: 114,
                        }}
                        className="nowrap ontop"
                      >
                        Alamat
                      </td>
                      <td
                        style={{ backgroundColor: "" }}
                        className="ontop mx-2 "
                      >
                        :
                      </td>
                      <td>{DetailSJRace.alamatBongkar}</td>
                    </tr>
                  </tbody>
                </table>
              </Col>
        </div>
        <br />

        <table
          style={{ fontSize: 12, border: "0.2px  black" }}
          className="table table-bordered"
        >
          <thead className="text-center">
            <tr>
              <th rowSpan="2" className="align-middle">
                NO
              </th>
              <th rowSpan="2" className="align-middle">
                NO SJ PELANGGAN
              </th>
              <th rowSpan="2" className="align-middle">
                NAMA PELANGGAN
              </th>
              <th colSpan="3">JUMLAH</th>
            </tr>
            <tr>
              <th>FAKTUR</th>
              <th>COLLY</th>
              <th>IKAT</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ textAlign: "center" }}>
              <td>{1}</td>
              <td>{DetailSJRace.sjErl}</td>
              <td>{DetailSJRace.sekolahTujuan}</td>
              <td>{DetailSJRace.jumlahFaktur}</td>
              <td>{DetailSJRace.koli}</td>
              <td>{DetailSJRace.ikat}</td>
            </tr>
            <tr>
              <td
                colSpan="3"
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                TOTAL
              </td>
              <td style={{ textAlign: "center" }}>
                {DetailSJRace.jumlahFaktur}
              </td>
              <td style={{ textAlign: "center" }}>{DetailSJRace.koli}</td>
              <td style={{ textAlign: "center" }}>{DetailSJRace.ikat}</td>
            </tr>
          </tbody>
        </table>
        <div style={{ fontSize: 8 }}>
          <b>Note :</b>
        </div>
        <div
          style={{
            border: "0.2px solid black",
            padding: 10,
            textAlign: "start",
          }}
        >
          <p>
            <b>
              <i style={{ fontSize: 16 }}>
              Buku yang diterima 100% menjadi tanggung jawab penerima dan
                    tidak bisa diserahkan ke pihak manapun tanpa sepengetahuan
                    pengirim. Jika ada yang pihak-pihak yang mengatasnamakan
                    RAJA CEPAT dapat menghubungi Customer Service PT Raja Cepat
                    Nusantara di nomor 089506185458.
              </i>
            </b>
          </p>
        </div>
        <br />
        <br />
        <br />
        <br />
        <div className="row text-center mt-3">
          <Col style={{ fontSize: 12 }}>
            <p>Pengirim</p>
            <br />
            <br />
            <br />
            <p>(.............................................)</p>
            <p>
              <i>Nama, Tanda Tangan, dan Tanggal</i>
            </p>
            <p>
              <i>No HP:............................................</i>
            </p>
          </Col>
          <Col style={{ fontSize: 12 }}>
            <p>Penerima</p>
            <br />
            <br />
            <br />
            <p>(.............................................)</p>
            <p>
              <i>Nama, Tanda Tangan, dan Tanggal</i>
            </p>
            <p>
              <i>No HP:............................................</i>
            </p>
          </Col>
        </div>
        {/* <hr className="new2" /> */}
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

export default SuratJalan;
