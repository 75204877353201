import { Button, Form, Input, notification } from "antd";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import logorace from "../../../assets/img/locorace.png";
import axios from "axios";
import { BaseUrlRace } from "../../../Api/BaseUrl";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./style.css";
import ReCAPTCHA from "react-google-recaptcha";

function LoginRaceCustomer() {
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const [captchaValue, setCaptchaValue] = useState("");
  const history = useHistory();

  async function loginCustomer() {
    if (!captchaValue) {
      notification.error({
        message: "Please complete the reCAPTCHA",
      });
      return;
    }

    const body = {
      username: username,
      password: password,
      captchaValue: captchaValue,
    };

    try {
      setLoading(true);
      const response = await axios.post(
        `${BaseUrlRace}auth/login-customer`,
        body
      );
      const data = response.data;

      notification.success({
        message: data.status?.message,
      });

      const loginData = data.data;
      localStorage.setItem("token", loginData.token);
      localStorage.setItem("jobdesk", loginData.divisi);
      localStorage.setItem("cabang", loginData.cabang);
      localStorage.setItem("loginBu", loginData.loginBu);
      setLoading(false);
      history.push("/race/cabang/dashboard");
    } catch (error) {
      const errorResponse = error.response.data;
      if (
        errorResponse &&
        errorResponse.errors &&
        Array.isArray(errorResponse.errors)
      ) {
        errorResponse.errors.forEach((errorItem) => {
          notification.error({
            message: errorItem.message || "Terjadi kesalahan pada server",
          });
        });
      } else {
        notification.error({
          message: "Terjadi kesalahan pada server",
        });
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <div
      className="d-flex justify-content-center warp-awal"
      style={{
        alignItems: "center",
        height: "100vh",
        background: "linear-gradient(to bottom right, #89CFF0, #7d57e8)",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        style={{
          backgroundColor: "#FF7A2F",
          boxShadow: "10px 4px 20px 2px rgba(1, 1, 0.1, 0.2)",
          maxHeight: "40rem",
          width: "500px",
          borderRadius: "10px",
          padding: 20,
        }}
      >
        <div className="login-from">
          <Row>
            <Col className="mx-4 mt-2">
              <div className="d-flex justify-content-center">
                <img src={logorace} alt="logo" />
              </div>
              <div
                className="d-flex justify-content-center mt-3"
                style={{ fontSize: 24, fontWeight: "bold", color: "#3a7ee8" }}
              >
                Login Dasboard Cabang Erlangga
              </div>
              <Form.Item className="mt-3">
                <div
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    marginBottom: 5,
                  }}
                >
                  Username
                </div>
                <Input
                  placeholder="Masukkan Username Cabang"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Form.Item>
              <Form.Item>
                <div
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    marginBottom: 5,
                  }}
                >
                  Password
                </div>
                <Input
                  placeholder="Masukkan Password Cabang"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Item>
            </Col>

           
            <div
              className="justify-content-center items-center d-flex"
              style={{ backgroundColor: "" }}
            >
              <Button
                onClick={loginCustomer}
                style={{
                  width: "100%",
                  backgroundColor: "#1f3d7d",
                  color: "white",
                }}
              >
                {loading ? "Loading..." : "Login"}
              </Button>
            </div>
            <ReCAPTCHA
              sitekey={`6LfxogsqAAAAANeKKQon9zQceO6h87yMmIuI80Vz`}
              onChange={(value) => {
                setCaptchaValue(value);
              }}
            />
          </Row>
        </div>
      </div>
    </div>
  );
}

export default LoginRaceCustomer;
