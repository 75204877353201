import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./SuratJalan.css";
import usePrintRaceStore from "../../../../zustand/Store/untukPrint/PrintRace";
import { Col, Row } from "react-bootstrap";
import JsBarcode from "jsbarcode-fixed";
import moment from "moment";

const PrintSPRace1Halaman = () => {
  const setDetailSJRace = usePrintRaceStore((state) => state.setDetailSJRace);
  const DetailSJRace = usePrintRaceStore((state) => state.DetailSJRace);
  const [DetailSJRaceState, setDetailSJRaceState] = useState("");
  useEffect(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    console.log(`iniurl`, urlParams);
    const dataString = urlParams.get("data");
    if (dataString) {
      const data = await JSON.parse(decodeURIComponent(dataString));
      console.log(`ini adataaa`, data);

      // Ubah data menjadi array jika bukan array
      const dataArray = Array.isArray(data) ? data : [data];

      setDetailSJRaceState(dataArray);
      setDetailSJRace(dataArray);
      JsBarcode(`#barcode`, `${dataArray[0].sm}`, {
        format: "CODE128",
        lineColor: "#00000",
        width: 1.9,
        height: 50,
        displayValue: true,
      });
    }
  }, [setDetailSJRace]);

  useEffect(() => {
    if (DetailSJRace.length > 0) {
      DetailSJRace.forEach((item, index) => {
        JsBarcode(`#barcode${index}`, item.so, {
          format: "CODE128",
          lineColor: "#00000",
          width: 1.9,
          height: 50,
          displayValue: true,
        });
      });
    }
    window.print();
  }, [DetailSJRace]);

  console.log(`iini DetailSJRace`, DetailSJRace);

  const date = new Date();
  const hariini = moment(date).format("YYYY-MM-DD");

  console.log(`DetailSJRaceState`, DetailSJRaceState);

  useEffect(() => {
    document.title = `SPRCPrint_All_${hariini}_1hal`;
  }, [hariini, DetailSJRace]);

  if (!DetailSJRaceState) {
    return <p>Loading</p>;
  }
  return (
    <div className="print-one-body" style={{ backgroundColor: "white" }}>
      {DetailSJRace && DetailSJRace.length > 0 ? (
        DetailSJRace.map((item, index) => (
          <div className="container print-one mb-5" key={index}>
            <div>
              <div className="d-flex">
                <Row style={{ width: "100%" }}>
                  <Col>
                    <img
                      className="justify-content-start"
                      src="https://track.rajacepat.com/assets/Race_New_Baru-6e871e8a.png"
                      alt=""
                    />
                  </Col>
                  <Col>
                    <div className="text-center justify-content-center">
                      <h2 style={{ fontSize: 16 }}>
                        <b>SURAT JALAN</b>
                      </h2>
                    </div>
                  </Col>
                  <Col>
                    <div className="text-end justify-content-end">
                      <svg id={`barcode${index}`}></svg>
                    </div>
                  </Col>
                </Row>
              </div>
              <Row className="mt-3">
                <Col style={{ fontSize: 12, fontWeight: "bold" }}>
                  <table>
                    <tbody>
                      <tr>
                        <td className="nowrap">Nomor Surat Jalan</td>
                        <td className="mx-2">:</td>
                        <td>{item.so}</td>
                      </tr>
                      <tr>
                        <td className="nowrap">Tanggal</td>
                        <td className="mx-2">:</td>
                        <td>{item.tglSp}</td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <Col style={{ fontSize: 12, fontWeight: "bold" }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>Nama Driver</td>
                        <td className="mx-2">:</td>
                        <td>{item.driver}</td>
                      </tr>
                      <tr>
                        <td>Plat Nomor</td>
                        <td className="mx-2">:</td>
                        <td>{item.nopol}</td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
              <Col style={{ fontSize: 12, fontWeight: "bold" }}>
                <tr className="" style={{ marginRight: 80 }}>
                  <td className="nowrap">Alamat</td>
                  <td className="mx-2" style={{ marginLeft: "50px" }}>
                    :
                  </td>
                  <td>{item.alamat}</td>
                </tr>
              </Col>
            </div>
            <table
              style={{ fontSize: 12, border: "2px solid black" }}
              className="table table-bordered mt-3"
            >
              <thead className="text-center">
                <tr>
                  <th rowSpan="2" className="align-middle">
                    NO
                  </th>
                  <th rowSpan="2" className="align-middle">
                    NO SJ PELANGGAN
                  </th>
                  <th rowSpan="2" className="align-middle">
                    NAMA PELANGGAN
                  </th>
                  <th colSpan="3">JUMLAH</th>
                </tr>
                <tr>
                  <th>FAKTUR</th>
                  <th>COLLY</th>
                  <th>IKAT</th>
                </tr>
              </thead>
              <tbody>
                <tr className="text-center">
                  <td>{1}</td>
                  <td>{item.SjErlangga}</td>
                  <td>{item.sekolahtujuan}</td>
                  <td>{item.jumlahFaktur}</td>
                  <td>{item.koli}</td>
                  <td>{item.ikat}</td>
                </tr>
                <tr>
                  <td
                    colSpan="3"
                    style={{ fontWeight: "bold", fontSize: "16px" }}
                  >
                    TOTAL
                  </td>
                  <td className="text-center">{item.jumlahFaktur}</td>
                  <td className="text-center">{item.koli}</td>
                  <td className="text-center">{item.ikat}</td>
                </tr>
              </tbody>
            </table>
            <div style={{ fontSize: 16 }}>
              <b>Note :</b>
            </div>
            <div
              style={{
                border: "2px solid black",
                padding: 10,
                textAlign: "start",
              }}
            >
              <p>
                <b>
                  <i style={{ fontSize: 16, fontWeight: "bold" }}>
                    Buku yang diterima 100% menjadi tanggung jawab penerima dan
                    tidak bisa diserahkan ke pihak manapun tanpa sepengetahuan
                    pengirim. Jika ada yang pihak-pihak yang mengatasnamakan
                    RAJA CEPAT dapat menghubungi Customer Service PT Raja Cepat
                    Nusantara di nomor 089506185458.
                  </i>
                </b>
              </p>
            </div>
            <div className="row text-center mt-3">
              <Col style={{ fontSize: 12, fontWeight: "bold" }}>
                <p>Pengirim</p>
                <p style={{ marginTop: 80 }}>
                  (.............................................)
                </p>
                <p>
                  <i>Nama, Tanda Tangan, dan Tanggal</i>
                </p>
                <p>
                  <i>No HP:............................................</i>
                </p>
              </Col>
              <Col style={{ fontSize: 12, fontWeight: "bold" }}>
                <p>Penerima</p>
                <p style={{ marginTop: 80 }}>
                  (.............................................)
                </p>
                <p>
                  <i>Nama, Tanda Tangan, dan Tanggal</i>
                </p>
                <p>
                  <i>No HP:............................................</i>
                </p>
              </Col>
            </div>
          </div>
        ))
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

export default PrintSPRace1Halaman;
